import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";

import { Layout } from "../components";

const Pipeline = ({ data }) => {
  const pipelineSections = _.sortBy(
    _.get(data, "allContentfulPipelineSection.nodes"),
    "order"
  );

  const trRowData = (data) => {
    return (
      <tr>
        <th className="program">{data.program}</th>
        <td className="indication">{data.indication}</td>
        {data.phase1 === data.phase2 ? (
          <td colSpan={2} className="both-phases">
            {parsePhase(data.phase1)}
          </td>
        ) : data.phase1 === 2 && data.phase2 === 3 ? (
          <td colSpan={2} className="both-phases transparentI">
            {parsePhase(data.phase1)}
          </td>
        ) : data.phase1 === 3 && data.phase2 === 0 ? (
          <td colSpan={2} className="both-phases transparentII">
            {parsePhase(data.phase1)}
          </td>
        ) : (
          <React.Fragment>
            <td className="phase desktop">{parsePhase(data.phase1)}</td>
            <td className="phase desktop">{parsePhase(data.phase2)}</td>
            <td className="phase mobile">{parsePhase(data.phase2)}</td>
            <td className="phase mobile">{parsePhase(data.phase1)}</td>
          </React.Fragment>
        )}
        <td className="milestone">
          {data.milestone}
          <div className="date">{data.milestoneDate}</div>
        </td>
      </tr>
    );
  };

  const parsePhase = (param) => {
    switch (param) {
      case 1:
        return "Discovery";
      case 2:
        return <div className="in-progress" />;
      case 3:
        return <div className="in-progress" />;
      default:
        return null;
    }
  };

  const trHeader = (next) => {
    return (
      <tr>
        <th className="program">Program</th>
        <th className="indication">Indication</th>
        <th className="phase desktop">Phase I</th>
        <th className="phase desktop">Phase II</th>
        <th className="phase mobile">Phase II</th>
        <th className="phase mobile">Phase I</th>
        <th className="milestone">{next ? "Next Milestone" : "Milestone"}</th>
      </tr>
    );
  };

  const renderSection = (pipelineSection) => {
    const pipelineTitle = _.get(pipelineSection, "title");
    const pipelineDescription = _.get(
      pipelineSection,
      "description.childMarkdownRemark.html"
    );
    const pipelineRows = _.get(pipelineSection, "pipelineRows");
    const pipelineNextMilestone = _.get(
      pipelineSection,
      "pipelineNextMilestone"
    );
    return (
      <section className="psychiatry">
        <h2 className="h4">{pipelineTitle}</h2>
        <div className="container">
          <div className="six columns">
            <div
              className={`description${!pipelineRows ? " mb0" : ""}`}
              dangerouslySetInnerHTML={{
                __html: pipelineDescription,
              }}
            />
          </div>
        </div>
        {pipelineRows ? (
          <div className="negative-layout-margin">
            <table role="none">
              <div className="negative-layout-spacer" />
              {trHeader(pipelineNextMilestone)}
              {pipelineRows.map((row, index) => {
                return trRowData(row, index);
              })}
              <div className="negative-layout-spacer" />
            </table>
          </div>
        ) : null}
        {/*<div className="small swipe">
          Swipe to see more
        </div>*/}
      </section>
    );
  };

  return (
    <Layout className="pipeline" pageData={_.get(data, "contentfulPageData")}>
      {pipelineSections.map((section, index) => {
        return renderSection(section, index);
      })}
    </Layout>
  );
};

export default Pipeline;

export const query = graphql`
  query pipelineQuery {
    contentfulPageData(title: { eq: "Pipeline" }) {
      title
      description
      image {
        file {
          url
        }
      }
      header
    }
    allContentfulPipelineSection {
      nodes {
        title
        order
        description {
          childMarkdownRemark {
            html
          }
        }
        pipelineRows {
          program
          indication
          phase1
          phase2
          milestone
          milestoneDate
        }
        nextMilestone
      }
    }
  }
`;
